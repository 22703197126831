import { render, staticRenderFns } from "./CountdownEventPage.vue?vue&type=template&id=c82340f0&scoped=true&"
import script from "./CountdownEventPage.vue?vue&type=script&lang=js&"
export * from "./CountdownEventPage.vue?vue&type=script&lang=js&"
import style0 from "./CountdownEventPage.vue?vue&type=style&index=0&id=c82340f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c82340f0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChrButton: require('/home/node/frontend/components/includes/ChrButton.vue').default})
