//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { format } from 'date-fns';
import { mapGetters } from 'vuex';
import Media from '../Media.vue';
import ClockSvg from 'static/svg/clock-blue.svg';
import Breadcrumb from '../includes/Breadcrumb.vue';

export default {
  components: { Media, ClockSvg, Breadcrumb },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: [],
      fontSize: 'small',
    };
  },
  computed: {
    ...mapGetters({
      blogCategories: 'blog/getBlogCategories',
    }),
    articleDate() {
      return format(new Date(this.page.post_date), 'MMM d, yyyy');
    },
    videoUrl() {
      if (this.page.featured_video) {
        if (this.page.featured_video.includes('www.youtube.com')) {
          return (
            this.page.featured_video + this.$t('blog.youtube_query_string')
          );
        }
        return this.page.featured_video;
      }
    },
  },
  methods: {
    getModuleData(page) {
      if (page.modules) {
        const modules = page.modules;
        for (let i = 0; i < modules.length; i++) {
          if (modules[i].data) {
            return modules[i].data;
          }
        }
      }
      return undefined;
    },
    mappedProducts(products) {
      if (products) {
        const mappedProds = products?.map(product => product.product_id);
        return mappedProds;
      }
      return [];
    },
    getBlogCategory(blog) {
      if (blog.terms) {
        let termId = blog.terms[0];
        return this.blogCategories[termId];
      }
      return false;
    },
    changeFontSize(size) {
      this.fontSize = size;

      var parent = document.getElementById('wysiwyg');
      var children = parent.getElementsByTagName('p');

      for (let i = 0; i < children.length; i++) {
        if (children[i].className === '') {
          if (this.fontSize === 'small') {
            children[i].style.fontSize = '14px';
          } else if (this.fontSize === 'medium') {
            children[i].style.fontSize = '18px';
          } else if (this.fontSize === 'large') {
            children[i].style.fontSize = '24px';
          }
        }
      }
    },
    buildBlogBreadcrumbs() {
      if (!this.page || !this.page.terms || this.page.terms.length === 0) {
        return [];
      }
      
      const links = [];


      // iterate through page.terms
      // foreach one add the title and url to the links array
      this.page.terms.forEach(termId => {
        const term = this.blogCategories[termId];
        if (term) {
          links.push({
            name: term.title,
            url: term.url,
          });
        }
      })

      // link to this page
      links.push({
        name: this.page.title,
        url: undefined,
      });

      return links;
    },
  },
  mounted() {
    this.data = this.getModuleData(this.page);
  },
};
