//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      source: 'cms',
    };
  },
};
