//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import iFrameResize from 'iframe-resizer/js/iframeResizer';
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    url() {
      const media = this.getMedia()({
        source: 'cms',
        id: this.data.pdf,
      });
      return media ? media.file : false;
    },
  },
  methods: {
    ...mapGetters({
      getMedia: 'media/getMedia',
    }),
    ...mapActions({
      fetchMedia: 'media/getMedia',
    }),
    async getPdf() {
      if (!this.data.pdf) return;
      await this.fetchMedia({
        source: 'cms',
        id: this.data.pdf,
      });
    },
  },
  serverPrefetch() {
    return this.getPdf();
  },
  async mounted() {
    if (!this.url) {
      await this.getPdf();
    }
    await this.getPdf();
    iFrameResize(
      {
        log: false,
      },
      '.bulletin_iframe'
    );
  },
};
