//
//
//
//
//
//
//
//

import gtmMixin from '~/mixins/gtm';

export default {
  mixins: [gtmMixin],
  props: {
    page: {
      type: Object,
    },
  },
  data() {
    return {
      shouldShowTestimonials: false,
    };
  },
  watch: {
    $route(val1, val2) {
      if (val1 !== val2) {
        this.handleModulesCheck();
      }
    },
  },
  methods: {
    handleModulesCheck() {
      let shouldShowTestimonials = true;

      if (this.page.modules) {
        this.page.modules.forEach(function(mod) {
          if (mod.module_type === 'SingleTestimonial') {
            shouldShowTestimonials = false; // Hide testimoinals section if single testimonial is there
            return;
          }
        });
      }
      this.shouldShowTestimonials = shouldShowTestimonials;
    },
  },
  async mounted() {
    this.handleModulesCheck();
    this.$nextTick(() => {
      this.forceOptimizeActive();
    });
  },
};
