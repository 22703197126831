//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FacebookSvg from '~/static/svg/facebook.svg';
import LinkedinSvg from '~/static/svg/linkedin.svg';

export default {
  components: {
    FacebookSvg,
    LinkedinSvg,
  },
  props: {
    page: {
      required: true,
      type: Object,
    },
  },
  computed: {
    shareLink() {
      return this.$config.baseUrl + this.page.url;
    },
  },
};
