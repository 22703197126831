//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      source: 'internal',
    };
  },
  computed: {
    ...mapGetters({
      categories: 'category/getHierarchicalCategories',
    }),
    filteredCategories() {
      return this.categories.filter(cat => cat.url);
    },
  },
};
