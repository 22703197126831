//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseIcon from '~/static/svg/cross.svg';

export default {
  components: {
    CloseIcon,
  },
  props: {
    videoID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      id: '',
      overlay: false,
      playerVars: {
        autoplay: 1,
      },
    };
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
  watch: {
    videoID(val) {
      if (val) {
        this.playVideo();
      } else {
        this.pauseVideo();
      }
    },
  },
  methods: {
    playVideo() {
      this.overlay = true;
      this.id = this.videoID;
      this.player.playVideo();
    },
    pauseVideo() {
      this.overlay = false;
      this.player.pauseVideo();
    },
    closeVideoPlayer() {
      this.$emit('close');
      this.pauseVideo();
    },
  },
};
