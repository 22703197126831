//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Media from '../Media.vue';

export default {
  components: { Media },
  props: {
    data: {
      type: Object,
    },
    page: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      blogCategories: 'blog/getBlogCategories',
    }),
  },
};
