import { render, staticRenderFns } from "./NewBlogShare.vue?vue&type=template&id=dcc998d8&scoped=true&"
import script from "./NewBlogShare.vue?vue&type=script&lang=js&"
export * from "./NewBlogShare.vue?vue&type=script&lang=js&"
import style0 from "./NewBlogShare.vue?vue&type=style&index=0&id=dcc998d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcc998d8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {XLink: require('/home/node/frontend/components/XLink.vue').default})
