//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueYoutubeMixin from '@/mixins/vueYoutube';
import CloseIcon from '~/static/svg/cross.svg';

export default {
  mixins: [VueYoutubeMixin],
  components: {
    CloseIcon,
  },
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    getVideoID(iframe) {
      /* eslint-disable */
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      /* eslint-enable */
      var match = iframe.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    },
  },
};
