//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    time: {
      type: String,
    },
    title: {
      type: String,
    },
    page: {
      type: Object,
    },
    objectIDs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    mappedProducts() {
      if (this.data) {
        const mappedProds = this.data?.map(product => product.product_id);
        return mappedProds;
      }
      return [];
    },
    isBlogPage() {
      return this?.page?.post_type === 'post';
    },
    isCheckoutPage() {
      return this?.page?.post_type === 'checkout';
    },
    pageType() {
      if (this.isBlogPage) return 'blog';
      else if (this.isCheckoutPage) return 'checkout';

      return 'related';
    },
    getObjectIDs() {
      return this.objectIDs ?? [];
    },
  },
};
