//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HomeSvg from '~/static/svg/home.svg';
import ArrowRight from '~/static/svg/arrow-right.svg';

import { getBreadcrumbLinks } from '~/util/cms-modules';

export default {
  components: {
    ArrowRight,
    HomeSvg,
  },
  props: {
    data: {
      type: Object,
    },
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mediaRefPrefix: 'subpage-banner',
    };
  },
  computed: {
    cutout() {
      return this.data.cutout ? 'cutout' : null;
    },
    hasForm() {
      return this.data.gravity_form ? true : false;
    },
    breadcrumbLinks() {
      if (this.page.post_type === 'bulletin') {
        // Special page type
        let links = [
          {
            name: this.$t('general.bulletin.breadcrumb_title'),
            url: this.$t('general.bulletin.link'),
          },
          {
            name: this.page.year,
            url: `${this.$t('general.bulletin.link')}${this.page.year}`,
          },
          {
            name: this.$dayjs()
              .month(this.page.month - 1)
              .format('MMMM'),
            url: undefined,
          },
        ];
        return links;
      }

      return getBreadcrumbLinks(this.page.title, this.page.parents);
    },
  },
  beforeDestroy() {
    window.addEventListener('click', this.checkingAnchorClick);
  },
  mounted() {
    this.$el.addEventListener('click', this.checkingAnchorClick);
    if (location.hash) {
      this.triggerAnimate(location.hash);
    }
  },
  methods: {
    checkingAnchorClick(event) {
      if (event.type === 'click') {
        if (
          event.toElement &&
          event.toElement.hash !== undefined &&
          event.toElement.hash.charAt(0) === '#'
        ) {
          event.preventDefault();
          this.triggerAnimate(event.toElement.hash);
        }
      }
    },
    triggerAnimate(target) {
      const elem = document.querySelector(target);

      if (!elem) {
        return;
      }

      window.scrollBy({
        top: elem.offsetTop - document.querySelector('header').clientHeight,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
