import { render, staticRenderFns } from "./TextImageOrVideo.vue?vue&type=template&id=5d6f7be4&scoped=true&"
import script from "./TextImageOrVideo.vue?vue&type=script&lang=js&"
export * from "./TextImageOrVideo.vue?vue&type=script&lang=js&"
import style0 from "./TextImageOrVideo.vue?vue&type=style&index=0&id=5d6f7be4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d6f7be4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Media: require('/home/node/frontend/components/Media.vue').default,LazyGForm: function () { return import('/home/node/frontend/components/GForm.vue' /* webpackChunkName: "components/GForm" */).then(function(m) { return m['default'] || m }) },VideoPopup: require('/home/node/frontend/components/includes/VideoPopup.vue').default})
