//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      selectedSlide: 0,
    };
  },
  methods: {
    timeClicked(pos) {
      this.selectedSlide = pos;
    },
  },
};
