//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gsap from 'gsap';
import VueYoutubeMixin from '@/mixins/vueYoutube';
import ArrowRight from '~/static/svg/arrow-right.svg';
import CloseIcon from '~/static/svg/cross.svg';
import sharedVariables from '~/util/shared-variables.js';

export default {
  mixins: [VueYoutubeMixin],
  components: {
    ArrowRight,
    CloseIcon,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      catLabel: this.$t('general.all'),
      videoId: '',
      categoryDropdownExpand: false,
      category: '',
      catSelection: 'all',
      playing: false,
      filteredVideos: [],
    };
  },
  watch: {
    catSelection(val1, val2) {
      if (val1 !== val2) {
        this.filteredVideos = this.filterVideos();
      }
    },
  },
  methods: {
    shouldShow(video) {
      if (this.catSelection === 'all') {
        return true;
      }
      return video.cats.includes(parseInt(this.catSelection));
    },
    updateCatLabel(cat = null) {
      if (cat) {
        this.catLabel = cat.name;
        this.catSelection = cat.id;
      } else {
        this.catLabel = this.$t('general.all');
        this.catSelection = 'all';
      }
      this.toggleDropdownMenu();
    },
    filterVideos() {
      let self = this;
      let newVideoSet = [];
      if (this.catSelection === 'all') {
        newVideoSet = this.data.videos;
      } else {
        newVideoSet = this.data.videos.filter(function(video) {
          return video.cats.includes(self.catSelection);
        });
      }
      return newVideoSet;
    },
    toggleDropdownMenu() {
      if (window) {
        if (window.innerWidth < sharedVariables.lg_min) {
          this.categoryDropdownExpand = !this.categoryDropdownExpand;
          let target = '.video__categories';
          let elem = this.$refs.videoCategoryList;
          gsap.to(target, 0.3, {
            height: this.categoryDropdownExpand ? elem.scrollHeight : 0,
            ease: 'power3.inOut',
          });
        } else {
          this.categoryDropdownExpand = false;
        }
      }
    },
  },
  mounted() {
    this.filteredVideos = this.filterVideos();
  },
};
