const VueYoutubeMixin = {
  data() {
    return {
      videoID: '',
    };
  },
  methods: {
    openVideoPlayer(videoID) {
      if (videoID) { // Don't pass the video id if it's false
        this.videoID = videoID;
      }
    },
    closeVideoPlayer() {
      this.videoID = '';
    }
  }
};

export default VueYoutubeMixin;
