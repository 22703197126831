import { render, staticRenderFns } from "./BuildersBulletin.vue?vue&type=template&id=416a1c04&"
import script from "./BuildersBulletin.vue?vue&type=script&lang=js&"
export * from "./BuildersBulletin.vue?vue&type=script&lang=js&"
import style0 from "./BuildersBulletin.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LazyGForm: function () { return import('/home/node/frontend/components/GForm.vue' /* webpackChunkName: "components/GForm" */).then(function(m) { return m['default'] || m }) },Media: require('/home/node/frontend/components/Media.vue').default})
