//
//
//
//
//
//
//
//
//
//
//
//

import PlayArrow from '~/static/svg/play-arrow.svg';

export default {
  components: {
    PlayArrow,
  },
  props: {
    video: {
      required: true,
    }
  },
  methods: {
    open () {
      this.$emit('open');
    }
  }
};
