//
//
//
//
//
//
//
//
//
//

import {
  getBreadcrumbLinks
} from '~/util/cms-modules';

export default {
  props: {
    page: {
      type: Object,
      required: true,
    }
  },
  computed: {
    breadcrumbLinks() {
      return getBreadcrumbLinks(this.page.title, this.page.parents);
    }
  }
};
