var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section panels-section white"},[_c('div',{staticClass:"panels"},[_c('div',{staticClass:"panels__inner",class:{
        yellow: _vm.data.background_color_left === 'yellow',
        blue: _vm.data.background_color_left === 'blue',
      }},[_c('div',{staticClass:"panels__content wysiwyg-editor",class:{
          parent_yellow: _vm.data.background_color_left === 'yellow',
          parent_blue: _vm.data.background_color_left === 'blue',
        },domProps:{"innerHTML":_vm._s(_vm.data.side_by_side_panel_one)}})]),_vm._v(" "),_c('div',{staticClass:"panels__inner",class:{
        yellow: _vm.data.background_color_right === 'yellow',
        blue: _vm.data.background_color_right === 'blue',
      }},[_c('div',{staticClass:"panels__content wysiwyg-editor",class:{
          parent_yellow: _vm.data.background_color_right === 'yellow',
          parent_blue: _vm.data.background_color_right === 'blue',
        },domProps:{"innerHTML":_vm._s(_vm.data.side_by_side_panel_two)}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }