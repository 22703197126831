//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DownloadIcon from '~/static/svg/download.svg';

export default {
  components: {
    DownloadIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
