import { render, staticRenderFns } from "./RelatedProducts.vue?vue&type=template&id=02fc2255&scoped=true&"
import script from "./RelatedProducts.vue?vue&type=script&lang=js&"
export * from "./RelatedProducts.vue?vue&type=script&lang=js&"
import style0 from "./RelatedProducts.vue?vue&type=style&index=0&id=02fc2255&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02fc2255",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductPageRelatedProducts: require('/home/node/frontend/components/pages/ProductPage/ProductPageRelatedProducts.vue').default})
