import { render, staticRenderFns } from "./NewBlogPage.vue?vue&type=template&id=3619fa1c&scoped=true&"
import script from "./NewBlogPage.vue?vue&type=script&lang=js&"
export * from "./NewBlogPage.vue?vue&type=script&lang=js&"
import style0 from "./NewBlogPage.vue?vue&type=style&index=0&id=3619fa1c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3619fa1c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/home/node/frontend/components/includes/Breadcrumb.vue').default,Media: require('/home/node/frontend/components/Media.vue').default,NewBlogShare: require('/home/node/frontend/components/blogs/NewBlogShare.vue').default,NewBlogSidebar: require('/home/node/frontend/components/blogs/NewBlogSidebar.vue').default,ProductPageRelatedProducts: require('/home/node/frontend/components/pages/ProductPage/ProductPageRelatedProducts.vue').default,TrendingProductsCarousel: require('/home/node/frontend/components/cms-modules/TrendingProductsCarousel.vue').default,Testimonials: require('/home/node/frontend/components/includes/Testimonials.vue').default,BuildersBulletin: require('/home/node/frontend/components/includes/BuildersBulletin.vue').default})
