//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    timeline: {
      required: true,
      type: Array,
    },
    selected: {
      required: true,
      type: Number,
    },
  },
};
