//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    isNewServiceLayout: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    videoUrl() {
      let videoUrl = this.data.embed_video_url || null;

      if (videoUrl?.includes('www.youtube.com')) {
        return this.data.embed_video_url + this.$t('blog.youtube_query_string');
      }
      return this.data.embed_video_url;
    },
  },
};
