//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import iFrameResize from 'iframe-resizer/js/iframeResizer';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    iFrameResize(
      {
        log: false,
      },
      '.bulletin_iframe'
    );
  },
};
