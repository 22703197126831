/**
 * @param {array} title Current page
 * @param {string} parents Parents of the current page
 *
 * @returns {Array} Array of links
 */
export function getBreadcrumbLinks(title, parents) {
  let links = [ // Current page
    {
      name: title,
      url: undefined,
    }
  ];

  if (!parents || parents.length === 0) {
    return links; // Normal pages
  }

  parents.slice(0).forEach(parent => {
    links.unshift(parent); // Nested pages
  });

  return links;
}