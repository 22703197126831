//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StorageMixins from '~/mixins/storage';
import { mapActions } from 'vuex';

export default {
  components: {
    Photoswipe: () => {
      if (process.client) {
        return import('v-photoswipe');
      }
    },
  },
  mixins: [StorageMixins],
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      link: '',
      isOpen: false,
      images: [],
      options: {
        index: 0,
        escKey: true,
      },
    };
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.convertLang();
      }
    },
  },
  methods: {
    ...mapActions({
      getMedia: 'media/getMedia',
    }),
    convertLang() {
      if (process.client) {
        let next = document.querySelector(
          '.pswp__button.pswp__button--arrow--right'
        );
        let prev = document.querySelector(
          '.pswp__button.pswp__button--arrow--left'
        );
        let fullScreen = document.querySelector(
          '.pswp__button.pswp__button--fs'
        );
        let close = document.querySelector('.pswp__button.pswp__button--close');
        let share = document.querySelector('.pswp__button.pswp__button--share');
        let zoom = document.querySelector('.pswp__button.pswp__button--zoom');
        if (next) next.setAttribute('title', 'Next');
        if (prev) prev.setAttribute('title', 'Previous');
        if (fullScreen) fullScreen.setAttribute('title', 'Full screen');
        if (close) close.setAttribute('title', 'Close');
        if (share) share.setAttribute('title', 'Share');
        if (zoom) zoom.setAttribute('title', 'Zoom');
      }
    },
    async getMediaResource(id) {
      return await this.getMedia({
        source: 'cms',
        id: id,
      });
    },
    async getAllImages() {
      this.data.gallery.forEach(async id => {
        let rawImage = null;

        if (this.$config.enableLocalStorage) {
          rawImage = this.getFromStorage('media', id);
          if (rawImage) {
            this.syncToStore('media', id, rawImage);
          } else {
            rawImage = await this.getMediaResource(id);
            this.addToStorage('media', id, rawImage);
          }
        } else {
          rawImage = await this.getMediaResource(id);
        }

        if (rawImage && rawImage.srcset && rawImage.srcset[0].url) {
          let image = {
            id: id,
            src: rawImage.srcset[0].url,
            w: parseInt(rawImage.size.split('-')[0]),
            h: parseInt(rawImage.size.split('-')[1]),
            title: rawImage.meta ? rawImage.meta.title : '',
          };
          this.images.push(image);
        }
      });
    },
    showPhotoSwipe(index) {
      this.options.index = index;
      this.isOpen = true;
    },
    hidePhotoSwipe() {
      this.isOpen = false;
    },
    toggleLightbox(index) {
      this.showPhotoSwipe(index);
    },
  },
  async mounted() {
    this.getAllImages();
  },
};
