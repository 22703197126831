//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
    },
    page: {
      type: Object,
      required: true,
    },
    isNewServiceLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currQuestionSelected: '',
    };
  },
  computed: {
    innerClass() {
      if (this.isNewServiceLayout) {
        return 'twisties__service-content';
      }
      return 'twisties__content';
    },
  },
  methods: {
    async changeQuestions(index) {
      if (this.currQuestionSelected === index) {
        this.currQuestionSelected = '';
        return;
      }

      this.currQuestionSelected = index;
      // wait for expanding/collapsing panels to finish
      await this.$nextTick();

      // on click, scroll the clicked anchor tag into view
      // each twisted item is approx 60px tall when collapsed
      const titleHeight = 58;
      // get element with class "twisties__container", then get its offsetTop
      const section = this.$refs.twistiesContent;
      // desktop header is 200px; mobile header is 64px;
      let headerOffset = window.innerWidth < 1024 ? 80 : 200;
      let startPos = section.offsetTop - headerOffset;
      if (startPos < 0) startPos = 0;
      // scroll further down depending on which panel in section has been clicked
      startPos += titleHeight * index;

      window.scroll({
        top: startPos,
        behavior: 'smooth',
      });
    },
  },
};
