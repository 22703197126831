//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    handleClick(title) {
      if (title.includes('Bowens')) {
        return (location.href = this.$t(
          'benefits_and_trade.bowens_location_url'
        ));
      } else if (title.includes('Trade')) {
        return (location.href = this.$t(
          'benefits_and_trade.trade_location_url'
        ));
      }
      return (location.href = this.$t(
        'benefits_and_trade.default_location_url'
      ));
    },
    clickedUrl(url) {
      if (url) {
        return (location.href = url);
      }
      return (location.href = this.$t(
        'benefits_and_trade.default_location_url'
      ));
    },
  },
  computed: {
    videoUrl() {
      if (this.data.video_embed) {
        if (this.data.video_embed.includes('www.youtube.com')) {
          return this.data.video_embed + this.$t('blog.youtube_query_string');
        }
        return this.data.video_embed;
      }
    },
  },
};
