//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sharedVariables from '~/util/shared-variables.js';

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      canShowDesktopBanner: true,
    };
  },

  computed: {},
  beforeMount() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.shouldShowDesktopBanner();
  },
  methods: {
    shouldShowDesktopBanner() {
      if (window.innerWidth < sharedVariables.md_min) {
        this.canShowDesktopBanner = false;
      } else {
        this.canShowDesktopBanner = true;
      }
    },
    handleResize() {
      this.shouldShowDesktopBanner();
    },
  },
};
