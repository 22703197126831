import { render, staticRenderFns } from "./SubPageBanner.vue?vue&type=template&id=5c426b2e&"
import script from "./SubPageBanner.vue?vue&type=script&lang=js&"
export * from "./SubPageBanner.vue?vue&type=script&lang=js&"
import style0 from "./SubPageBanner.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/home/node/frontend/components/includes/Breadcrumb.vue').default,LazyGForm: function () { return import('/home/node/frontend/components/GForm.vue' /* webpackChunkName: "components/GForm" */).then(function(m) { return m['default'] || m }) },Media: require('/home/node/frontend/components/Media.vue').default})
