//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sharedVariables from '~/util/shared-variables.js';

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      options: {
        arrows: false,
        dots: true,
        cssEase: 'linear',
        fade: true,
        lazyLoad: true,
        rows: 4,
        slidesPerRow: 4,
        autoplaySpeed: 4000,
        autoplay: false,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 550,
            settings: {
              slidesPerRow: 2,
            },
          },
          {
            breakpoint: 766,
            settings: {
              slidesPerRow: 3,
            },
          },
          {
            breakpoint: sharedVariables.md_max,
            settings: {
              slidesPerRow: 4,
            },
          },
        ],
      },
    };
  },
};
