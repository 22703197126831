//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import iFrameResize from 'iframe-resizer/js/iframeResizer';

export default {
  props: {
    data: {
      type: Object,
    },
    page: {
      type: Object,
      required: true,
    },
    isNewServiceLayout: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    breadcrumbLinks() {
      if (!this.data.is_for_banner) return undefined;
      let links = [
        {
          name: this.page.title,
          url: undefined,
        },
      ]; // Current page
      if (!this.page.parents || this.page.parents.length === 0) {
        return links;
      }
      this.page.parents.slice(0).forEach(parent => {
        links.unshift(parent);
      });
      return links;
    },
    componentInnerClass() {
      if (this.isNewServiceLayout) {
        return 'contentonly__service-inner';
      } else {
        return 'contentonly__inner';
      }
    },
  },
  mounted() {
    let iframes = document.querySelector('iframe');
    if (iframes) {
      iframes.addEventListener('load', function() {
        iFrameResize(
          {
            bodyBackground: 'transparent',
            checkOrigin: false,
          },
          iframes
        );
      });
    }
  },
};
