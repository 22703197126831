//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Media from '../Media.vue';
import InstagramSvg from '~/static/svg/instagram.svg';
import FacebookSvg from '~/static/svg/facebook.svg';
import YoutubeSvg from '~/static/svg/youtube.svg';

export default {
  components: { Media, InstagramSvg, FacebookSvg, YoutubeSvg },
  props: {
    page: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      blogCategories: 'blog/getBlogCategories',
    }),
    relatedPosts() {
      if (this.page.type === 'single-blog') {
        return this.page.related_posts;
      }
      return null;
    },
  },
};
