//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sharedVariables from '~/util/shared-variables.js';
import gsap from 'gsap';

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      marginTop: 32,
      options: {
        slidesToShow: this.data.delivery_timeline.length,
        slidesToScroll: this.data.delivery_timeline.length,
        arrows: false,
        infinite: false,
        swipe: false,
        adaptiveHeight: true,
        draggable: true,
        lazyLoad: 'progressive',
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: sharedVariables.sm_max,
            settings: {
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              swipe: true,
              draggable: true,
              centerMode: true,
              centerPadding: '30px',
            },
          },
          {
            breakpoint: sharedVariables.md_max,
            settings: {
              dots: true,
              swipe: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '27%',
            },
          },
        ],
      },
    };
  },
  methods: {
    animateBox(index, show) {
      const targetDiv = `.home-delivery-single-${index} .timeline__box-content-wrapper`;
      const dom = this.$el.querySelector(targetDiv);
      if (window.innerWidth >= sharedVariables.lg_min && dom) {
        gsap.to(targetDiv, {
          marginTop: show ? this.marginTop : 0,
          height: show
            ? dom.querySelector('.timeline__content').scrollHeight
            : 0,
          ease: 'ease',
          duration: 0.45,
          delay: 0,
        });
      }
    },
    moveTruck(pos = 'truckpos0', index) {
      const el = this.$refs[pos];
      if (el && window.innerWidth >= sharedVariables.lg_min) {
        // Slider changes at 1180
        gsap.to('.truck', 0.7, {
          x:
            el.offsetLeft +
            el.scrollWidth / 2 -
            this.$refs.truck.clientWidth / 2 -
            20,
          ease: 'power3.inOut',
        });
      }
      this.animateBox(index, true);
    },
    calcPaddingTop() {
      if (window.innerWidth < sharedVariables.lg_min) {
        this.marginTop = 10;
      }
    },
  },
  mounted() {
    this.moveTruck();
    this.calcPaddingTop();
  },
};
