//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sharedVariables from '~/util/shared-variables.js';

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      canShowDesktopBanner: true,
      canShowMobileBanner: false,
    };
  },
  watch: {
    data() {
      this.calcScreenSize();
    },
  },
  computed: {
    hasDesktopImage() {
      return (this.data && this.data.img_desk) || false;
    },
    getMobileImageId() {
      let mobileId = this.data.service_image_mobile;

      if (mobileId) {
        return mobileId.toString();
      }

      return this.data.img_desk.toString();
    },
    getDesktopImageId() {
      let desktopId = this.data.img_desk;

      if (desktopId) {
        return desktopId.toString();
      }

      return undefined;
    },
  },
  beforeMount() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  async mounted() {
    await this.calcScreenSize();
  },
  methods: {
    async calcScreenSize() {
      this.canShowDesktopBanner = false;
      this.canShowMobileBanner = false;

      const innerWidth = window.innerWidth;
      const mdMax = sharedVariables.md_max;
      const smMax = sharedVariables.sm_max;

      const isMobile = innerWidth <= smMax;
      const isXL = innerWidth > mdMax;

      if (isXL) {
        this.canShowDesktopBanner = true;
      } else if (isMobile) {
        this.canShowMobileBanner = true;
      }

      return;
    },
    handleResize() {
      this.calcScreenSize();
    },
  },
};
