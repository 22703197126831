//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//Import sharedVariables from '~/util/shared-variables.js';
//import {ObserveVisibility} from 'vue-observe-visibility';
//import VueYoutubeMixin from '~/mixins/vueYoutube';
//import CloseIcon from '~/static/svg/cross.svg';
import InstagramSvg from '~/static/svg/instagram.svg';
import FacebookSvg from '~/static/svg/facebook.svg';
import YoutubeSvg from '~/static/svg/youtube.svg';

//Import PlayArrow from '~/static/svg/play-arrow.svg';

export default {
  //Mixins: [VueYoutubeMixin],
  components: {
    //CloseIcon,
    InstagramSvg,

    //PlayArrow,
    FacebookSvg,
    YoutubeSvg,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      isVisible: true,
      isDesktop: false,
      currentIndex: 0,
      options: {
        dots: true,
        arrows: false,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        pauseOnDotHover: true,
        autoplaySpeed: undefined,
        waitForAnimate: false,
      },
    };
  },
  computed: {
    // ShouldUseVideoPopup() {
    //   if (this.data && this.data.home_banner_slider.length > 0) {
    //     let shouldShow = false;
    //     this.data.home_banner_slider.forEach(slide => {
    //       if (
    //         slide.home_banner_slider_full_video &&
    //         slide.home_banner_slider_full_video.vid
    //       ) {
    //         shouldShow = true;
    //         return;
    //       }
    //     });
    //     return shouldShow;
    //   }
    //
    //   return false;
    // },
    sliders() {
      if (!this.data || this.data.home_banner_slider.length < 1) {
        return [];
      }

      return this.data.home_banner_slider;
    },
  },

  // BeforeDestroy() {
  //   window.removeEventListener('resize', this.handleResize);
  // },
  // beforeMount() {
  //   window.addEventListener('resize', this.handleResize);
  // },
  // mounted() {
  //   this.handleResize();
  // },
  mounted() {
    const autoplaySpeed = parseInt(this.$t('home.banner.autoplay_speed'));
    this.options.autoplaySpeed = autoplaySpeed;
  },
  methods: {
    // SetYoutubeVars(vid) {
    //   return {
    //     mute: true,
    //     autoplay: false,
    //     controls: 0,
    //     fs: 0,
    //     loop: 1,
    //     rel: 0,
    //     playlist: vid
    //   };
    // },
    // startSlideYoutubeVideo(index, isVisible, isDesktop) {
    //   if (!isVisible || !isDesktop) {
    //     return false;
    //   }
    //
    //   if (this.sliders[index] && this.sliders[index].video) {
    //     let vid = this.sliders[index].video.vid;
    //
    //     if (this.$refs[vid] && this.$refs[vid].player) {
    //       this.$refs[vid].player.playVideo();
    //     }
    //
    //     return true;
    //   }
    //
    //   return false;
    // },
    // stopSlideYoutubeVideo(index) {
    //   if (this.sliders[index] && this.sliders[index].video) {
    //     let vid = this.sliders[index].video.vid;
    //
    //     if (this.$refs[vid] && this.$refs[vid].player) {
    //       this.$refs[vid].player.stopVideo();
    //     }
    //
    //     return true;
    //   }
    //
    //   return false;
    // },
    slideChanged(oldIndex, newIndex) {
      this.currentIndex = newIndex;

      // This.stopSlideYoutubeVideo(oldIndex);
      // this.startSlideYoutubeVideo(newIndex, this.isVisible, this.isDesktop);
    },

    // VisibilityChanged(isVisible) {
    //   this.isVisible = isVisible;
    //   if (isVisible) {
    //     this.startSlideYoutubeVideo(
    //       this.currentIndex,
    //       this.isVisible,
    //       this.isDesktop
    //     );
    //   } else {
    //     this.stopSlideYoutubeVideo(this.currentIndex);
    //   }
    // }
  },

  // Directives: {
  //   ObserveVisibility
  // }
};
