//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        slidesToShow: 1,
        fade: true,
        arrows: false,
        dots: true,
        adaptiveHeight: true,
        lazyLoad: 'progressive',
      },
    };
  },
};
