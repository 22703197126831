//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import iFrameResize from 'iframe-resizer/js/iframeResizer';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    isNewServiceLayout: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    iFrameResize(
      {
        log: false,
      },
      '.video_iframe'
    );
  },
};
