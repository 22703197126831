//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueYoutubeMixin from '@/mixins/vueYoutube';
import PlayIcon from '~/static/svg/play-arrow.svg';

export default {
  components: {
    PlayIcon,
  },
  mixins: [VueYoutubeMixin],
  props: {
    data: {
      type: Object,
    },
    isNewServiceLayout: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    imageWrapperInnerClass() {
      if (this.isNewServiceLayout) {
        return 'image-or-video__service-image-wrapper';
      } 
      
      return 'image-or-video__image-wrapper';
    },
    contactWrapperInnerClass() {
      if (this.isNewServiceLayout) {
        return 'image-or-video__service-content';
      } 
      
      return 'image-or-video__content';
    }
  }
};
